import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";

const HomeOne = lazy(() => import("./pages/HomeOne"));
const Project = lazy(() => import("./pages/Work"));
const Apartments = lazy(() => import("./pages/Apartments"));
const About = lazy(() => import("./pages/About"));
const WorkDetails = lazy(() => import("./pages/WorkDetails"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const BlogCategories = lazy(() => import("./pages/BlogCategories"));
const BlogTag = lazy(() => import("./pages/BlogTag"));
const Highlights = lazy(() => import("./pages/Highlights"));
const Contact = lazy(() => import("./pages/Contact"));
const Location = lazy(() => import("./pages/Location"));

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<div />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              element={<HomeOne />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/about"}`}
              element={<About />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/projects"}`}
              element={<Project />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/location"}`}
              element={<Location />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/apartments"}`}
              element={<Apartments />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/apartment/:id"}`}
              element={<WorkDetails />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
              element={<BlogGrid />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
              element={<BlogTag />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
              element={<BlogCategories />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
              element={<BlogDetails />}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/highlights"}`}
              element={<Highlights />}
            />

            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              element={<Contact />}
            />
          </Routes>
        </Suspense>
      </NavScrollTop>
    </Router>
  );
}

export default App;
